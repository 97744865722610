import React from 'react'
import { navigate } from 'gatsby'

import Fade from 'react-reveal/Fade'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'
import RoomDescGallery from '../components/roomDescGallery'
import RoomDescSimge from '../components/roomDescSimple'

import ImageIntersection from '../images/intersecci-n-6.svg'
import ImageIntersection2 from '../images/intersecci-n-8.svg'

import ImageRoom1 from '../images/captura-de-pantalla-2020-01-22-a-la-s-14-13-16.png'
import ImageRoom2 from '../images/captura-de-pantalla-2020-01-22-a-la-s-14-13-16@2x.png'
import ImageRoom3 from '../images/captura-de-pantalla-2020-01-22-a-la-s-14-13-16@3x.png'

import ImageRoomExtra1 from '../images/captura-de-pantalla-2020-01-22-a-la-s-14-15-24.png'
import ImageRoomExtra2 from '../images/captura-de-pantalla-2020-01-22-a-la-s-14-15-24@2x.png'
import ImageRoomExtra3 from '../images/captura-de-pantalla-2020-01-22-a-la-s-14-15-24@3x.png'

import { BEACH_CLUB } from '../constants'

const RoomsDetails = () => {
  const goToHostingPage = () => {
    navigate('/hospedaje')
  }

  return (
    <Layout
      gradient={{
        background: 'linear-gradient(to bottom, #202945, rgba(32, 41, 69, 0))',
      }}
    >
      <SEO title="El Careyes" />
      <div className="rooms-details__main-image rooms-details__main-image--club">
        <header>
          <Fade top duration={2000}>
            <h1 className="main-image__header">El Careyes</h1>
            <h2 className="main-image__header main-image__header--smaller">
              Beach Club &amp; Residences
            </h2>
          </Fade>
        </header>
        <Fade duration={3000} delay={300}>
          <div className="main-image__description">
            <p>
              El Careyes Club &amp; Residences is a newly renovated luxurious
              beachfront development that has recently opened in the heart of
              Careyes, Mexico. Originally known as Hotel El Careyes, this
              property offers beautiful condominiums that will be available for
              your stay.
            </p>
            <p>
              El Careyes Club &amp; Residences is located inside the gated
              community of Careyes, next to Casitas De Las Flores complex, and
              walking distance from Playa Rosa. This development is a homage to
              the old, with an emphasis on luxurious relaxation and exquisite
              hotel service. The residences feature a contemporary atmosphere
              and style, providing top tier accommodations for guests.
            </p>
          </div>
        </Fade>
        <Button onClick={goToHostingPage}>REGRESAR</Button>
      </div>
      <section className="rooms-details__content">
        <Fade duration={2000}>
          <RoomDescGallery {...BEACH_CLUB} />
        </Fade>
      </section>

      <Fade duration={2000}>
        <section className="rooms-details__offers">
          <header>
            <h1 className="description__header">El Careyes</h1>
            <h3 className="description__subheader">offers:</h3>
          </header>
          <p className="description__text">
            5 infinity pools, Private access beach club, 2 fully equipped Tennis
            courts, as well as a restaurant and bar open breakfast and lunch.
            There are 1, 2, and 3 bedroom accommodations each with a private
            bathroom for each bedroom. Living Room, Dining Room, and fully
            equipped kitchen, TV with Sky satellite connection and WIFI, as well
            as daily cleaning service. Price starts at $360/night (plus taxes
            and fees).
          </p>
        </section>
      </Fade>
      <section>
        <RoomDescSimge
          mainImage={[ImageRoom1, ImageRoom2, ImageRoom3]}
          roomImage={[ImageRoomExtra1, ImageRoomExtra2, ImageRoomExtra3]}
        />
      </section>
      <section className="rooms-details__footer">
        <Fade>
          <p className="footer__section">
            Maecenas sodales laoreet dui vitae imperdiet. Vestibulum iaculis sem
            eu nulla vulputate, vestibulum finibus nisi volutpat. Cras finibus
            augue vel ex posuere, sit amet fermentum diam imperdiet. In et nibh
            ex. Sed faucibus arcu eu hendrerit vestibulum. Morbi eget turpis sed
            purus porta volutpat. Nam sit amet tincidunt magna.
          </p>
        </Fade>
        <img
          src={ImageIntersection2}
          alt="decorator"
          className="nido-section__image-decorator-2"
        />
      </section>
      <img
        src={ImageIntersection}
        alt="decorator"
        className="nido-section__image-decorator"
      />
    </Layout>
  )
}

export default RoomsDetails
